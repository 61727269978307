/* feedback */
/* typography */
/* border */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLFj_Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJnedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDD4Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins Black'), local('Poppins-Black'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLBT5Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v15/S6u9w4BMUTPHh7USSwaPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v15/S6uyw4BMUTPHjxAwWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v15/S6u9w4BMUTPHh6UVSwaPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v15/S6u9w4BMUTPHh50XSwaPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url(https://fonts.gstatic.com/s/sourcesanspro/v12/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmRdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v12/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7g.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url(https://fonts.gstatic.com/s/sourcesanspro/v12/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v12/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmRdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url(https://fonts.gstatic.com/s/sourcesanspro/v12/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwmRdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJfedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z1xlEA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #5e5e5e;
  font-size: 1rem;
  font-family: 'Source Sans Pro', sans-serif;
  overflow-x: hidden;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  color: #2B2B2B;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}
body a {
  text-decoration: none !important;
  color: inherit;
}
body a:hover,
body a:focus,
body a:active {
  color: unset;
}
body img {
  display: block;
  max-width: 100%;
}
body .m-t-5 {
  margin-top: 5px;
}
body .m-t-10 {
  margin-top: 10px;
}
body .m-t-15 {
  margin-top: 15px;
}
body .m-t-20 {
  margin-top: 20px;
}
body .m-t-25 {
  margin-top: 25px;
}
body .m-t-30 {
  margin-top: 30px;
}
body .m-b-5 {
  margin-bottom: 5px;
}
body .m-b-10 {
  margin-bottom: 10px;
}
body .m-b-15 {
  margin-bottom: 15px;
}
body .m-b-20 {
  margin-bottom: 20px;
}
body .m-b-25 {
  margin-bottom: 25px;
}
body .m-b-30 {
  margin-bottom: 30px;
}
body .p-t {
  padding-top: 15px;
}
body .p-t-5 {
  padding-top: 5px;
}
body .p-t-10 {
  padding-top: 10px;
}
body .p-t-15 {
  padding-top: 15px;
}
body .p-t-20 {
  padding-top: 20px;
}
body .p-t-25 {
  padding-top: 25px;
}
body .p-t-30 {
  padding-top: 30px;
}
body .p-b-5 {
  padding-bottom: 5px;
}
body .p-b-10 {
  padding-bottom: 10px;
}
body .p-b-15 {
  padding-bottom: 15px;
}
body .p-b-20 {
  padding-bottom: 20px;
}
body .p-b-25 {
  padding-bottom: 25px;
}
body .p-b-30 {
  padding-bottom: 30px;
}
body .text-content h2,
body .ck-editor h2,
body .text-content h3,
body .ck-editor h3,
body .text-content h4,
body .ck-editor h4 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
body .text-content ul,
body .ck-editor ul,
body .text-content ol,
body .ck-editor ol,
body .text-content p,
body .ck-editor p {
  margin-bottom: 0.625rem;
}
body .text-content ul:last-child,
body .ck-editor ul:last-child,
body .text-content ol:last-child,
body .ck-editor ol:last-child,
body .text-content p:last-child,
body .ck-editor p:last-child {
  margin-bottom: 0;
}
body .text-content ul,
body .ck-editor ul,
body .text-content ol,
body .ck-editor ol {
  padding-left: 24px;
}
body .text-content ul,
body .ck-editor ul {
  list-style-type: disc;
}
body .text-content ol,
body .ck-editor ol {
  list-style-type: decimal;
}
body .text-content ul li,
body .ck-editor ul li,
body .text-content ol li,
body .ck-editor ol li {
  line-height: 1.5;
  margin-top: 0.3125rem;
}
body .text-content em,
body .ck-editor em {
  font-style: italic;
}
body .text-content u,
body .ck-editor u {
  text-decoration: underline;
}
body .text-content blockquote,
body .ck-editor blockquote {
  color: #3A4F8C;
  margin-left: 1.5625rem;
}
.system-alerts {
  width: 100%;
  text-align: center;
}
.system-alerts .alert {
  border-radius: 0;
  border: none;
  height: auto;
  min-height: 60px;
  display: flex;
  align-items: center;
  opacity: 0.9;
  transition: all 0.4s;
  margin-bottom: 0;
  position: relative;
}
.system-alerts .alert .alert-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system-alerts .alert .alert-inner .img-hidden {
  visibility: hidden;
  opacity: 0;
  height: 18px;
}
.system-alerts .alert .alert-inner p {
  color: #fff;
  padding: 0 50px;
}
.system-alerts .alert .alert-inner button {
  color: #fff;
  opacity: 1;
  font-weight: 300;
  float: none;
}
.system-alerts .alert .alert-inner button img {
  height: 18px;
}
.system-alerts .alert.alert-success {
  background: #76ad21;
}
.system-alerts .alert.alert-info {
  background: #218ead;
}
.system-alerts .alert.alert-warning {
  background: #c52b20;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 625px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
nav.section-header {
  z-index: 10;
  width: 100%;
  background-color: #ffffff;
  position: relative;
  border-bottom: 1px solid rgba(43, 43, 43, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.075);
}
nav.section-header.no-border {
  border: none;
  box-shadow: none;
}
nav.section-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background-color: #3A4F8C;
}
nav.section-header .container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .flexbox-logo {
  background-color: #3A4F8C;
  height: 100%;
  display: block;
  min-height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3.125rem;
}
nav.section-header .flexbox-logo img {
  height: 65px;
  width: auto;
  display: block;
}
nav.section-header .header-content {
  background-color: #fff;
  flex: 1;
}
nav.section-header .header-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.625rem 0;
  color: #3A4F8C;
  color: #d93656;
}
nav.section-header .header-top a {
  display: flex;
  align-items: center;
}
nav.section-header .header-top a img {
  height: 17px;
  width: 17px;
  margin-right: 5px;
}
nav.section-header .header-top ul {
  display: flex;
  align-items: center;
}
nav.section-header .header-top ul li {
  margin-left: 1.5625rem;
}
nav.section-header .header-top ul li a {
  display: flex;
  align-items: center;
}
nav.section-header .header-top ul li a img {
  height: 17px;
  width: 17px;
  margin-right: 5px;
}
nav.section-header .header-flexbox {
  padding: 0.625rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  padding: 0.3125rem 0.625rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #3A4F8C;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child {
  padding-right: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child a {
  padding-right: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child {
  position: relative;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child .dropdown-list {
  position: absolute;
  flex-wrap: wrap;
  justify-content: space-between;
  top: 100%;
  background-color: #fff;
  padding: 15px;
  display: flex;
  transform: translate(-50%, 2%);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(43, 43, 43, 0.05);
  border-radius: 5px;
  z-index: 1001;
  width: 600px;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child .dropdown-list li {
  width: 48.5%;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child:hover .dropdown-list,
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child:focus .dropdown-list {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 0);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  display: inline-block;
  position: relative;
  padding: 0.3125rem;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: #d93656;
  opacity: 0.75;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #222;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
.section-footer {
  padding: 3.125rem 0 0.625rem;
  background-color: #2B2B2B;
  color: rgba(255, 255, 255, 0.825);
}
.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
}
.section-footer .footer-item {
  width: calc(25% - 0.625rem);
}
.section-footer .footer-item__heading {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.section-footer .footer-list li {
  margin-top: 0.3125rem;
}
.section-footer address {
  margin-bottom: 0;
}
.section-footer address p {
  margin-top: 0.3125rem;
}
.section-footer .alert.alert-info {
  border: none;
  border-radius: 0;
  border-top: 1px solid rgba(153, 153, 153, 0.175);
  background-color: transparent;
  margin-top: 3.125rem;
  padding: 0.625rem 0;
  color: inherit;
  font-size: 0.875rem;
}
.section-footer .footer-credits {
  margin-top: 3.125rem;
  font-size: 0.875rem;
  padding: 0.625rem 0;
  border-top: 1px solid rgba(153, 153, 153, 0.175);
}
.section-footer .footer-credits a {
  color: #fff;
}
.btn {
  padding: 10px 35px;
  border: 2px solid transparent;
  line-height: 1;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn--md {
  padding: 1rem 3.125rem;
  font-size: 1rem;
}
.btn--primary {
  color: #fff;
  background-color: #3A4F8C;
  border: 2px solid #3A4F8C;
}
.btn--primary:hover,
.btn--primary:focus,
.btn--primary:active {
  color: #fff;
}
.btn--secondary {
  color: #fff;
  background-color: #d93656;
  border: 2px solid #d93656;
}
.btn--secondary:hover,
.btn--secondary:focus,
.btn--secondary:active {
  color: #fff;
}
.btn--white {
  color: #5e5e5e;
  background-color: #fff;
  border: 2px solid #fff;
}
.btn--white:hover,
.btn--white:focus,
.btn--white:active {
  color: #2B2B2B;
}
.section {
  padding: 3.125rem 0;
  position: relative;
}
.section--dark {
  background-color: #3A4F8C;
  color: #fff;
}
.section--dark .section__heading-title {
  color: #fff;
}
.section__heading {
  text-align: center;
}
.section__heading-title {
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  display: inline-block;
  margin: 0 auto;
}
.section__heading-title:after {
  content: '';
  position: absolute;
  bottom: -0.625rem;
  right: 50%;
  transform: translateX(50%);
  width: 65%;
  height: 2px;
  background-color: #d93656;
  opacity: 0.75;
}
.section__heading-subtitle {
  font-size: 1.25rem;
  margin-top: 1rem;
}
/* --------------------------------
---- Section welcome ---- */
.section-welcome {
  position: relative;
  min-height: 600px;
  background-color: #3A4F8C;
}
/* --------------------------------
---- Slider ---- */
.owl-carousel-slider {
  height: 70vh;
  height: 600px;
  position: absolute;
  border-radius: 5px 0 0 5px;
  overflow: hidden;
}
.owl-carousel-slider .slide {
  height: 70vh;
  height: 600px;
}
.owl-carousel-slider .slide .container {
  position: relative;
  height: 70vh;
  height: 600px;
}
.owl-carousel-slider .slide__content {
  position: absolute;
  left: 3.125rem;
  top: 50%;
  transform: translateY(-50%);
  width: 60%;
  color: #fff;
  padding: 3.125rem 0;
}
.owl-carousel-slider .slide__content-title {
  font-size: 54px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 2px;
  opacity: 0;
}
.owl-carousel-slider .slide__content-para {
  font-size: 1.625rem;
  margin-top: 0.625rem;
  opacity: 0;
}
.owl-carousel-slider .slide__content .btn {
  margin-top: 3.125rem;
  opacity: 0;
}
.owl-carousel-slider .owl-item.active .slide__content-title {
  animation: 1250ms 1250ms fadeInUp forwards;
}
.owl-carousel-slider .owl-item.active .slide__content-para {
  animation: 1250ms 1500ms fadeInUp forwards;
}
.owl-carousel-slider .owl-item.active .btn {
  animation: 1250ms 1750ms fadeInUp forwards;
}
/* --------------------------------
---- Offer ---- */
.section-offer-tiles {
  background-color: #fafafb;
}
.offer-grid {
  margin-top: 3.125rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 300px;
  grid-gap: 1rem;
}
.offer-tile {
  background-size: cover;
  background-position: center center;
  position: relative;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(rgba(0, 0, 0, 0.205), rgba(0, 0, 0, 0.205));
  border-radius: 5px;
}
.offer-tile__name {
  position: absolute;
  left: 1rem;
  color: #fff;
  text-transform: capitalize;
}
.offer-tile__name span {
  font-size: 0.875rem;
  font-weight: 400;
}
.offer-tile__button {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: inline-block;
  padding: 0.625rem 1rem;
  color: #5e5e5e;
  border: 2px solid #fff;
  border-radius: 100px;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  transition: all 150ms;
}
.offer-tile__button:hover,
.offer-tile__button:focus,
.offer-tile__button:active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}
/* --------------------------------
---- Animals ---- */
.section-animals {
  margin-top: 3.125rem;
  background-color: #3A4F8C;
  height: 550px;
  position: relative;
}
.animals__flexbox .image-wrapper {
  position: absolute;
  top: -3.125rem;
  left: 0;
  width: 65%;
  height: calc(100% + 3.125rem);
}
.animals__flexbox .image-wrapper img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.animals__flexbox .animals__description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  width: 50%;
  max-width: 625px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  padding: 3.125rem;
}
.animals__flexbox .animals__description .text-content {
  margin-top: 1.5625rem;
}
.animals__flexbox .animals__description .btn {
  margin-top: 3.125rem;
  display: inline-block;
  margin-left: auto;
}
.animals__action-wrapper {
  margin-top: 3.125rem;
  display: flex;
  justify-content: center;
}
/* --------------------------------
---- Partners ---- */
.section-partners {
  padding: 0;
}
.owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}
/* --------------------------------
---- News ---- */
.section-news .action-wrapper {
  margin-top: 3.125rem;
  display: flex;
  justify-content: center;
}
.articles-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3.125rem;
}
.articles-flexbox .article-prev {
  display: block;
  width: calc(100% / 3 - 1rem);
  margin-top: 3.125rem;
  padding: 1rem;
  border: 1px solid rgba(43, 43, 43, 0.05);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.025);
  border-radius: 5px;
}
.articles-flexbox .article-prev:nth-child(-n + 3) {
  margin-top: 0;
}
.articles-flexbox .article-prev--hidden {
  visibility: hidden !important;
  opacity: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 0 !important;
}
.articles-flexbox .article-prev .image-wrapper-link {
  overflow: hidden;
  display: block;
}
.articles-flexbox .article-prev h3 {
  margin-top: 0.625rem;
  font-size: 1.25rem;
}
.articles-flexbox .article-prev p {
  margin-top: 0.625rem;
}
.articles-flexbox .article-prev .read-more {
  color: #3A4F8C;
  display: block;
  margin-left: auto;
  margin-top: 1.5625rem;
  text-align: right;
}
.pagination {
  margin-top: 3.125rem;
}
.pagination__list {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.pagination__list li {
  margin: 0 0.5rem;
}
.pagination__list li a {
  border-bottom: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.55rem;
  font-size: 1rem;
  transition: all 200ms;
}
.pagination__list li a:hover,
.pagination__list li a:active {
  color: #3A4F8C;
  border-bottom: 1px solid #d93656;
}
.pagination__list li.active a {
  color: #3A4F8C;
  border-bottom: 1px solid #d93656;
}
/* --------------------------------
---- Section About ---- */
.section-about {
  position: relative;
  color: #fff;
  height: 550px;
}
.section-about__map {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 65%;
}
.section-about #location-map {
  height: 100%;
  min-height: 550px;
  width: 100%;
  display: block;
}
.section-about .map-marker__content {
  color: #5e5e5e;
  text-align: center;
}
.section-about .map-marker__desc {
  margin-top: 0.3125rem;
}
.about-content {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: calc(60% - 1.5625rem);
  z-index: 1;
  background-color: #3A4F8C;
  padding: 3.125rem 3.125rem 3.125rem 200px;
  border-radius: 5px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}
.about-content h2 {
  color: #fff;
  font-size: 3rem;
}
.about-content h2 span {
  font-weight: 400;
  font-size: 2rem;
}
.about-content .text-content {
  margin-top: 1.5625rem;
}
.about-content .btn {
  margin-top: 3.125rem;
}
/* ------------------------------------
---- Aktualności Wpis - strona ---- */
section.article-section-content .article-image-wrapper {
  margin-top: 3.125rem;
}
section.article-section-content .article-image-wrapper img {
  margin: 0 auto;
  display: block;
  width: 70%;
  border-radius: 5px;
}
section.article-section-content .article-content {
  margin-top: 3.125rem;
}
/* --------------------------------
---- O firmie              ---- */
section.about-section-content .o-firmie-content {
  margin-top: 3.125rem;
}
section.about-section-content .o-firmie-content .text-content {
  margin-top: 3.125rem;
}
section.about-section-content .o-firmie-content .text-content:first-child {
  margin-top: 0;
}
section.about-section-content .o-firmie-content .img-1 {
  width: 70%;
  border-radius: 5px;
  margin: 3.125rem auto;
  display: block;
}
@media (max-width: 768px) {
  section.about-section-content .o-firmie-content .img-1 {
    width: 100%;
  }
}
section.about-section-content .section-partners {
  margin-top: 3.125rem;
}
section.about-section-content .gallery-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3.125rem;
}
section.about-section-content .gallery-flexbox__item {
  width: calc(25% - 20px);
  height: 150px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
section.about-section-content .gallery-flexbox__item a {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
}
section.about-section-content .gallery-flexbox__item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
section.about-section-content .gallery-flexbox__item--hidden {
  height: 0 !important;
  padding: 0;
  margin: 0 !important;
  opacity: 0;
  visibility: hidden;
}
/* --------------------------------
---- Kontakt              ---- */
section.contact-section-content .contact-flexbox {
  display: flex;
  justify-content: space-between;
}
section.contact-section-content .contact-flexbox .contact-service {
  width: 48%;
}
section.contact-section-content .contact-flexbox .contact-service h3 {
  margin-bottom: 1.5625rem;
}
section.contact-section-content .contact-flexbox .contact-service address {
  font-size: 1.25rem;
}
section.contact-section-content .contact-flexbox .contact-service address strong {
  color: #d93656;
  font-size: 1.625rem;
}
section.contact-section-content .contact-flexbox .contact-service .contact-tel,
section.contact-section-content .contact-flexbox .contact-service .contact-mail {
  font-size: 1.25rem;
}
section.contact-section-content .contact-flexbox .contact-service .contact-form .label {
  margin-bottom: 0;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
}
section.contact-section-content .contact-flexbox .contact-service .contact-form .input {
  border-color: rgba(43, 43, 43, 0.05);
  border-radius: 3px;
  background-color: #fafafb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.075);
}
section.contact-section-content .contact-flexbox .contact-service .contact-form .form-group:nth-of-type(6),
section.contact-section-content .contact-flexbox .contact-service .contact-form .form-group:nth-of-type(7) {
  margin-bottom: 0.3125rem;
}
section.contact-section-content .contact-flexbox .contact-service .contact-form .form-group .form-check .form-check-label {
  font-size: 0.875rem;
}
section.section-contact-map .location-map-wrapper {
  height: 450px;
  width: 100%;
}
/* --------------------------------
---- Referencje - strona   ---- */
section.referencje-section-content {
  background-color: #fff;
}
section.referencje-section-content .testimonials-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
section.referencje-section-content .testimonials-flexbox .testimonials-service {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.125rem;
  border: 1px solid rgba(43, 43, 43, 0.05);
  border-radius: 5px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.025);
  margin-top: 3.125rem;
  text-align: center;
  background-color: #fafafb;
}
section.referencje-section-content .testimonials-flexbox .testimonials-service:nth-child(even) {
  margin-top: 6.25rem;
}
section.referencje-section-content .testimonials-flexbox .testimonials-service img {
  border-radius: 50%;
  width: 100px;
  margin: 0 auto;
}
section.referencje-section-content .testimonials-flexbox .testimonials-service h3 {
  margin-top: 1.5625rem;
  font-size: 1.625rem;
}
section.referencje-section-content .testimonials-flexbox .testimonials-service p {
  margin-top: 1.5625rem;
}
/* --------------------------------
---- Faq - strona          ---- */
section.faq-section-content .faq-flexbox {
  margin-top: 3.125rem;
}
section.faq-section-content .faq-flexbox .faq-service {
  width: 85%;
  margin-top: 3.125rem;
  padding: 3.125rem;
  border-top: 1px solid rgba(43, 43, 43, 0.05);
  border-bottom: 1px solid rgba(43, 43, 43, 0.05);
  background-color: #fafafb;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.025);
}
section.faq-section-content .faq-flexbox .faq-service:nth-child(even) {
  text-align: right;
  margin-left: auto;
}
section.faq-section-content .faq-flexbox .faq-service:nth-child(even) .question {
  margin-left: auto;
}
section.faq-section-content .faq-flexbox .faq-service .question {
  color: #d93656;
  font-weight: 400;
  font-size: 1.25rem;
  width: 70%;
}
section.faq-section-content .faq-flexbox .faq-service .answer {
  margin-top: 1.5625rem;
}
/* --------------------------------
---- Komitet - strona      ---- */
section.komitet-section-content .text-content {
  margin-top: 3.125rem;
  width: 80%;
}
section.komitet-section-content .komitet-cta {
  width: 690px;
  background-color: #d93656;
  color: #fff;
  border-radius: 5px;
  margin: 3.125rem 0 0;
  padding: 3.125rem;
  text-align: center;
  font-size: 1.25rem;
}
section.komitet-section-content .komitet-cta h3 {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1rem;
}
section.komitet-section-content .komitet-cta .bank {
  margin-top: 1rem;
  font-size: 1.625rem;
  font-weight: 700;
}
section.komitet-section-pictures .animals-gallery {
  margin-top: -1.5625rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.komitet-section-pictures .animals-gallery .image-wrapper {
  margin-top: 1.5625rem;
  width: calc(100% / 5 - 1rem);
  border-radius: 5px;
  overflow: hidden;
  height: 175px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}
section.komitet-section-pictures .animals-gallery .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
section.komitet-section-pictures .animals-gallery .image-wrapper--hidden {
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  visibility: hidden;
}
section.komitet-section-content-more .text-content {
  width: 80%;
}
/* --------------------------------
---- Oferta - strona       ---- */
section.oferta-section-content .offer-product-flexbox {
  display: flex;
  justify-content: space-between;
  margin-top: 3.125rem;
}
section.oferta-section-content .offer-product-image-wrapper {
  width: calc(40% - 3.125rem);
}
section.oferta-section-content .offer-product-image-wrapper img {
  border-radius: 5px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.125);
}
section.oferta-section-content .offer-product-description {
  width: 60%;
}
section.oferta-section-content .offer-product-description .text-content {
  margin-top: 1.5625rem;
}
section.oferta-section-content .offer-product-description .offer-product-features {
  margin: 1.5625rem 0 0 0;
  list-style: none;
  padding: 0 0;
}
section.oferta-section-content .offer-product-description .offer-product-features li {
  padding-left: 1.5625rem;
  text-indent: -0.625rem;
}
section.oferta-section-content .offer-product-description .offer-product-features li::before {
  content: "• ";
  position: relative;
  left: -0.3125rem;
  color: red;
}
section.oferta-section-content .offer-product-description .calculator-link {
  margin-top: 3.125rem;
  display: flex;
  display: inline-block;
  padding: 1rem 1.5625rem;
  line-height: 1;
  background-color: #3A4F8C;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 5px;
}
section.oferta-section-content .offer-product-description .calculator-link img {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 0.625rem;
}
/* ----------------------------------------
---- Polityki regulaminy - strona  ---- */
.section-policy-content .text-content {
  margin-top: 3.125rem;
}
@media (max-width: 1279.98px) {
  .animals__flexbox .animals__description {
    right: 1rem;
    left: unset;
  }
}
@media (max-width: 1199.98px) {
  .about-content {
    padding: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  nav.section-header .flexbox-logo {
    padding: 0 1.5625rem;
    min-height: 85px;
  }
  nav.section-header .header-content {
    height: 100px;
  }
  nav.section-header .header-top ul li:nth-child(2) {
    display: none;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    height: 100%;
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    margin: 0;
    padding: 0.3125rem 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child a {
    padding-right: 0.625rem;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child .dropdown-list {
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    top: unset;
    transform: translate(0, 0);
    box-shadow: none;
    border: none;
    border-radius: 0;
    width: auto;
    list-style: none;
    opacity: 1;
    visibility: visible;
    transition: all 300ms;
    padding: 0;
    max-height: 0;
    overflow: hidden;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child .dropdown-list li {
    width: auto;
    font-size: 0.875rem;
    font-weight: 400;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child.active .dropdown-list {
    max-height: 600px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child:hover .dropdown-list,
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.has-child:focus .dropdown-list {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    padding: 0.625rem;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  .section-footer .footer-flexbox {
    flex-wrap: wrap;
  }
  .section-footer .footer-item {
    width: calc(50% - 0.625rem);
  }
  .section-footer .footer-item:nth-child(1),
  .section-footer .footer-item:nth-child(4) {
    order: -1;
  }
  .section-footer .footer-item:nth-child(2),
  .section-footer .footer-item:nth-child(3) {
    margin-top: 3.125rem;
  }
  .offer-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .about-content {
    padding: 1.5625rem;
  }
  .about-content h2 {
    font-size: 2rem;
  }
  .about-content .btn {
    margin-top: 3.125rem;
  }
  .section-animals {
    margin-top: 0;
    padding: 0;
    height: 500px;
    border-top: 1px solid rgba(43, 43, 43, 0.05);
    background-color: #fff;
  }
  .animals__flexbox {
    display: flex;
    justify-content: space-between;
    height: 500px;
  }
  .animals__flexbox .image-wrapper {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    width: 50%;
    height: 100%;
    border-radius: 0;
  }
  .animals__flexbox .image-wrapper img {
    border-radius: 0;
  }
  .animals__flexbox .animals__description {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    transform: translate(0, 0);
    width: auto;
    max-width: unset;
    width: 50%;
    box-shadow: none;
    border-radius: 0;
    padding: 3.125rem 1.5625rem;
  }
  .animals__flexbox .animals__description .btn {
    margin-top: 1.5625rem;
  }
  section.faq-section-content .faq-flexbox .faq-service {
    width: 100%;
    margin-top: 1.5625rem;
  }
  section.faq-section-content .faq-flexbox .faq-service .question {
    width: 90%;
  }
  section.referencje-section-content .testimonials-flexbox .testimonials-service {
    padding: 1rem;
  }
  .articles-flexbox .article-prev {
    width: calc(100% / 3 - 0.625rem);
  }
  .articles-flexbox .article-prev:nth-child(-n + 3) {
    margin-top: 0;
  }
  section.komitet-section-content .text-content {
    width: 100%;
  }
  section.komitet-section-content .komitet-cta {
    width: 100%;
  }
  section.komitet-section-pictures .animals-gallery .image-wrapper {
    width: calc(100% / 4 - 0.625rem);
    margin-top: 1rem;
  }
  section.komitet-section-content-more .text-content {
    width: 100%;
  }
  section.about-section-content .gallery-flexbox__item {
    width: calc(100% / 3 - 0.625rem);
  }
}
@media (max-width: 767.98px) {
  nav.section-header .flexbox-logo {
    padding: 0 1.5625rem;
    min-height: 85px;
  }
  nav.section-header .header-top a {
    display: none;
  }
  nav.section-header .header-top ul li:nth-child(2) {
    display: none;
  }
  .owl-carousel-slider .slide__content {
    left: 1.5625rem;
    width: 75%;
  }
  .owl-carousel-slider .slide__content-title {
    font-size: 40px;
  }
  .owl-carousel-slider .slide__content-para {
    font-size: 1rem;
  }
  .owl-carousel-slider .slide__content .btn {
    padding: 0.625rem 1.5625rem;
  }
  .section-about {
    position: relative;
    color: #fff;
    height: auto;
    padding: 0;
  }
  .section-about__map {
    position: relative;
    top: unset;
    right: unset;
    height: 350px;
    width: 100%;
  }
  .section-about #location-map {
    min-height: unset;
  }
  .about-content {
    position: relative;
    top: unset;
    left: unset;
    transform: translateY(0);
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }
  .section-animals {
    height: auto;
  }
  .animals__flexbox {
    flex-wrap: wrap;
    height: auto;
  }
  .animals__flexbox .image-wrapper {
    width: 100%;
  }
  .animals__flexbox .animals__description {
    width: 100%;
    padding: 3.125rem 15px;
  }
  section.oferta-section-content .offer-product-flexbox {
    flex-wrap: wrap;
  }
  section.oferta-section-content .offer-product-image-wrapper {
    width: 100%;
  }
  section.oferta-section-content .offer-product-image-wrapper img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  section.oferta-section-content .offer-product-description {
    width: 100%;
    margin-top: 3.125rem;
  }
  section.faq-section-content .faq-flexbox .faq-service {
    padding: 1.5625rem;
  }
  section.contact-section-content .contact-flexbox {
    flex-wrap: wrap;
  }
  section.contact-section-content .contact-flexbox .contact-service {
    width: 100%;
  }
  section.contact-section-content .contact-flexbox .contact-service:nth-child(2) {
    margin-top: 3.125rem;
  }
  section.section-contact-map .location-map-wrapper {
    height: 450px;
    width: 100%;
  }
  .articles-flexbox .article-prev {
    width: calc(100% / 2 - 0.625rem);
    margin-top: 1.5625rem;
  }
  .articles-flexbox .article-prev:nth-child(-n + 3) {
    margin-top: 1.5625rem;
  }
  .articles-flexbox .article-prev:nth-child(-n + 2) {
    margin-top: 0;
  }
  section.komitet-section-content .komitet-cta {
    padding: 1.5625rem;
  }
  section.komitet-section-content .komitet-cta h3 {
    font-size: 1.625rem;
  }
  section.komitet-section-content .komitet-cta h3 span {
    display: block;
  }
  section.komitet-section-content .komitet-cta .bank {
    font-size: 1.25rem;
  }
  section.komitet-section-pictures .animals-gallery .image-wrapper {
    width: calc(100% / 3 - 0.625rem);
    height: 150px;
  }
}
@media (max-width: 575.98px) {
  nav.section-header .flexbox-logo {
    padding: 0 15px 0 0;
    min-height: 85px;
  }
  nav.section-header .header-content {
    height: auto;
  }
  nav.section-header .header-top {
    padding: 0.3125rem 0;
  }
  nav.section-header .header-top a {
    display: none;
  }
  nav.section-header .header-top ul li {
    margin: 0;
  }
  nav.section-header .header-top ul li:nth-child(2) {
    display: none;
  }
  section.about-section-content .gallery-flexbox__item {
    height: 125px;
  }
  section.referencje-section-content .testimonials-flexbox .testimonials-service {
    width: 100%;
    margin-top: 1.5625rem;
  }
  section.referencje-section-content .testimonials-flexbox .testimonials-service:nth-child(even) {
    margin-top: 1.5625rem;
  }
  .articles-flexbox .article-prev {
    width: calc(100% / 2 - 0.625rem);
  }
  section.komitet-section-content .komitet-cta {
    font-size: 1rem;
  }
  section.komitet-section-content .komitet-cta h3 {
    font-size: 1.25rem;
  }
  section.komitet-section-content .komitet-cta .bank {
    font-size: 1rem;
  }
}
@media (max-width: 500px) {
  .offer-grid {
    grid-template-columns: 1fr;
    grid-auto-rows: 250px;
  }
  .section-footer .footer-item {
    width: 100%;
    margin-top: 3.125rem;
    text-align: center;
  }
  .section-footer .footer-item:nth-child(1),
  .section-footer .footer-item:nth-child(4) {
    order: -1;
  }
  .section-footer .footer-item:first-child {
    margin-top: 0;
  }
  .articles-flexbox .article-prev {
    width: 100%;
    margin-top: 1.5625rem;
  }
  .articles-flexbox .article-prev:nth-child(-n + 3) {
    margin-top: 1.5625rem;
  }
  .articles-flexbox .article-prev:first-child {
    margin-top: 0;
  }
  section.komitet-section-pictures .animals-gallery .image-wrapper {
    width: calc(100% / 2 - 0.3125rem);
    margin-top: 0.625rem;
  }
}
@media (max-width: 400px) {
  .owl-carousel-slider .slide__content-title {
    font-size: 2rem;
  }
  section.oferta-section-content .offer-product-description .calculator-link {
    font-size: 1rem;
  }
  section.about-section-content .gallery-flexbox__item {
    width: calc(100% / 2 - 0.625rem);
  }
  .system-alerts .alert .alert-inner p {
    padding: 0 ;
  }
}
@media (max-width: 335px) {
  nav.section-header .flexbox-logo {
    padding: 0 15px 0 0;
  }
  nav.section-header .flexbox-logo img {
    height: 50px;
  }
  nav.section-header .header-top ul li a img {
    display: none;
  }
}
