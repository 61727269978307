@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

body {
    min-height: 100vh;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: @color-text;
    font-size: @text-md;
    font-family: @font-primary;
    overflow-x: hidden;

    &.no-scroll {
        overflow: hidden;
        max-height: 100vh;
    }

    ul,
    ol,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: @color-text-heading;
        font-family: @font-secondary;
        font-weight: 700;
    }

    a {
        text-decoration: none !important;
        color: inherit;

        &:hover,
        &:focus,
        &:active {
            color: unset;
        }
    }

    img {
        display: block;
        max-width: 100%;
    }

    .m-t-5 {
        margin-top: 5px;
    }

    .m-t-10 {
        margin-top: 10px;
    }

    .m-t-15 {
        margin-top: 15px;
    }

    .m-t-20 {
        margin-top: 20px;
    }

    .m-t-25 {
        margin-top: 25px;
    }

    .m-t-30 {
        margin-top: 30px;
    }

    .m-b-5 {
        margin-bottom: 5px;
    }

    .m-b-10 {
        margin-bottom: 10px;
    }

    .m-b-15 {
        margin-bottom: 15px;
    }

    .m-b-20 {
        margin-bottom: 20px;
    }

    .m-b-25 {
        margin-bottom: 25px;
    }

    .m-b-30 {
        margin-bottom: 30px;
    }

    .p-t {
        padding-top: 15px;
    }

    .p-t-5 {
        padding-top: 5px;
    }

    .p-t-10 {
        padding-top: 10px;
    }

    .p-t-15 {
        padding-top: 15px;
    }

    .p-t-20 {
        padding-top: 20px;
    }

    .p-t-25 {
        padding-top: 25px;
    }

    .p-t-30 {
        padding-top: 30px;
    }

    .p-b-5 {
        padding-bottom: 5px;
    }

    .p-b-10 {
        padding-bottom: 10px;
    }

    .p-b-15 {
        padding-bottom: 15px;
    }

    .p-b-20 {
        padding-bottom: 20px;
    }

    .p-b-25 {
        padding-bottom: 25px;
    }

    .p-b-30 {
        padding-bottom: 30px;
    }

    .text-content,
    .ck-editor {

        h2,
        h3,
        h4 {
            margin-top: @space-sm;
            margin-bottom: @space-sm;
        }

        ul,
        ol,
        p {
            margin-bottom: @space-sm;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul,
        ol {
            padding-left: 24px;
        }

        ul {
            list-style-type: disc;
        }

        ol {
            list-style-type: decimal;
        }

        ul li,
        ol li {
            line-height: @body-line-height;
            margin-top: @space-xs;
        }

        em {
            font-style: italic;
        }

        u {
            text-decoration: underline;
        }

        blockquote {
            color: @color-primary;
            margin-left: @space-lg;
        }
    }
}

.system-alerts {
    width: 100%;
    text-align: center;

    .alert {
        border-radius: 0;
        border: none;
		height: auto;
		min-height: 60px;
        display: flex;
        align-items: center;
        opacity: 0.9;
		transition: all 0.4s;
		margin-bottom: 0;
		position: relative;

        .alert-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .img-hidden {
                visibility: hidden;
                opacity: 0;
                height: 18px;
            }

            p {
                color: #fff;
                padding: 0 50px;
            }

            button {
                color: #fff;
                opacity: 1;
                font-weight: 300;
                float: none;

                img {
                    height: 18px;
                }
            }
        }

        &.alert-success {
            background: #76ad21;
        }

        &.alert-info {
            background: #218ead;
        }

        &.alert-warning {
            background: #c52b20;
        }
    }
}

.modal-service-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.5);
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .modal-service {
        padding: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;

        .service-inner {
            background: #fff;
            width: 625px;
            max-width: 100%;
            padding: 50px 25px 25px 25px;
            text-align: center;
            position: relative;

            .service-button {
                position: absolute;
                top: 20px;
                right: 20px;
                border: none;
                background: none;
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;

                .button-bar {
                    display: block;
                    position: absolute;
                    width: 20px;
                    height: 2px;
                    background: #222;

                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(2) {
                        transform: rotate(-45deg);
                    }
                }
            }

            .service-content {
                .heading {
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }

                .btn {
                    display: flex;
                    justify-content: center;
                    margin-top: 5px;
                }
            }

            .service-buttons {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .btn-link {
                    padding: 0;
                    text-transform: uppercase;
                    font-weight: 700;
                    text-decoration: none;
                }
            }
        }
    }
}

nav.section-header {
    z-index: 10;
    width: 100%;
    background-color: rgba(@white, 1);
    position: relative;
    border-bottom: 1px solid @color-border-light;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.075);

    &.no-border {
        border: none;
        box-shadow: none;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background-color: @color-primary;
    }

    .container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .flexbox-logo {
        background-color: @color-primary;
        height: 100%;
        display: block;
        min-height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 @space-xl;

        img {
            height: 65px;
            width: auto;
            display: block;
        }
    }

    .header-content {
        background-color: @white;
        flex: 1;
    }

    .header-top {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: @space-sm 0;
        // border-bottom: 1px solid rgba(@color-primary, .24);
        color: @color-primary;
        color: @color-secondary;


        a {
            display: flex;
            align-items: center;

            img {
                height: 17px;
                width: 17px;
                margin-right: 5px;
            }
        }

        ul {
            display: flex;
            align-items: center;

            li {
                margin-left: @space-lg;

                a {
                    display: flex;
                    align-items: center;

                    img {
                        height: 17px;
                        width: 17px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .header-flexbox {
        padding: @space-sm 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .flexbox-nav-wrapper {
            .nav-button-close {
                position: absolute;
                display: none;
                width: 100%;
                top: 20px;
                left: 0;

                .container {
                    display: flex;
                    justify-content: flex-end;

                    &:before,
                    &:after {
                        content: none;
                    }
                }

                button {
                    outline: none;
                    border: none;
                    background: none;
                    padding: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .button-bar {
                        display: block;
                        position: absolute;
                        width: 30px;
                        height: 2px;
                        background: #222;

                        &:nth-child(1) {
                            transform: rotate(45deg);
                        }

                        &:nth-child(2) {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            .flexbox-nav-inner {
                .flexbox-nav {
                    display: flex;
                    align-items: center;

                    li {
                        padding: @space-xs @space-sm;
                        font-size: @space-md;
                        text-transform: uppercase;
                        font-weight: 600;
                        color: @color-primary;

                        &:last-child {
                            padding-right: 0;

                            a {
                                padding-right: 0;
                            }
                        }

                        &.has-child {
                            position: relative;

                            .dropdown-list {
                                position: absolute;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                top: 100%;
                                background-color: #fff;
                                padding: 15px;
                                display: flex;
                                transform: translate(-50%, 2%);
                                box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
                                border: 1px solid @color-border-light;
                                border-radius: 5px;
                                z-index: 1001;
                                width: 600px;
                                list-style: none;
                                opacity: 0;
                                visibility: hidden;
                                transition: all 300ms;

                                li {
                                    width: 48.5%;
                                }
                            }

                            &:hover,
                            &:focus {
                                .dropdown-list {
                                    opacity: 1;
                                    visibility: visible;
                                    transform: translate(-50%, 0);
                                }
                            }
                        }

                        a {
                            display: inline-block;
                            position: relative;
                            padding: @space-xs;

                            &:after {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                width: 100%;
                                height: 2px;
                                background-color: transparent;
                            }

                            &.active {
                                &:after {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    right: 0;
                                    width: 100%;
                                    height: 2px;
                                    background-color: @color-secondary;
                                    opacity: 0.75;
                                }
                            }
                        }
                    }
                }
            }
        }

        .nav-button {
            padding: 10px;
            border: none;
            background: none;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            outline: none;
            display: none;

            .button-bar {
                height: 2px;
                width: 24px;
                background: #222;
                border-radius: 10px;
                transition: all 0.4s;

                &:nth-child(2) {
                    margin-top: 4px;
                    width: 27px;
                }

                &:nth-child(3) {
                    margin-top: 4px;
                    width: 30px;
                }
            }

            &:hover,
            &:focus {
                .button-bar {
                    width: 30px !important;
                }
            }
        }
    }
}

.section-footer {
    padding: @space-xl 0 @space-sm;
    background-color: @gray-3;
    color: @white-subtle;

    .footer-flexbox {
        display: flex;
        justify-content: space-between;
    }

    .footer-item {
        width: calc(25% - @space-sm);

        &__heading {
            font-size: @text-lg;
            font-weight: 700;
            margin-bottom: @space-md;
        }
    }

    .footer-list {
        li {
            margin-top: @space-xs;
        }
    }

    address {
        margin-bottom: 0;

        p {
            margin-top: @space-xs;
        }
    }

    .alert.alert-info {
        border: none;
        border-radius: 0;
        border-top: 1px solid @gray-0;
        background-color: transparent;
        margin-top: @space-xl;
        padding: @space-sm 0;
        color: inherit;
        font-size: @text-sm;
    }

    .footer-credits {
        margin-top: @space-xl;
        font-size: @text-sm;
        padding: @space-sm 0;
        border-top: 1px solid @gray-0;

        a {
            color: @white;
        }
    }
}

.btn {
    padding: 10px 35px;
    border: 2px solid transparent;
    line-height: 1;
    border-radius: 100px;
    text-transform: uppercase;
    font-weight: 600;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.125);

    &--md {
        padding: @space-md @space-xl;
        font-size: @text-md;
    }

    &--primary {
        color: @white;
        background-color: @color-primary;
        border: 2px solid @color-primary;

        &:hover,
        &:focus,
        &:active {
            color: @white;
        }
    }

    &--secondary {
        color: @white;
        background-color: @color-secondary;
        border: 2px solid @color-secondary;

        &:hover,
        &:focus,
        &:active {
            color: @white;
        }
    }

    &--white {
        color: @color-text;
        background-color: @white;
        border: 2px solid @white;

        &:hover,
        &:focus,
        &:active {
            color: @color-text-heading;
        }
    }
}

.section {
    padding: @space-xl 0;
    position: relative;

    &--dark {
        background-color: @color-primary;
        color: @white;

        .section__heading-title {
            color: @white;
        }
    }

    &__heading {
        text-align: center;
    }

    &__heading-title {
        font-size: @text-xxl;
        font-weight: 700;
        position: relative;
        display: inline-block;
        margin: 0 auto;

        &:after {
            content: '';
            position: absolute;
            bottom: -@space-sm;
            right: 50%;
            transform: translateX(50%);
            width: 65%;
            height: 2px;
            background-color: @color-secondary;
            opacity: 0.75;
        }
    }

    &__heading-subtitle {
        font-size: @text-lg;
        margin-top: @space-md;
    }
}

/* --------------------------------
---- Section welcome ---- */
.section-welcome {
    position: relative;
    min-height: 600px;
    background-color: @color-primary;
}

/* --------------------------------
---- Slider ---- */
.owl-carousel-slider {
    height: 70vh;
    height: 600px;
    position: absolute;
    border-radius: 5px 0 0 5px;
    overflow: hidden;

    .slide {
        height: 70vh;
        height: 600px;

        .container {
            position: relative;
            height: 70vh;
            height: 600px;
        }

        &__content {
            position: absolute;
            left: @space-xl;
            top: 50%;
            transform: translateY(-50%);
            width: 60%;
            color: @white;
            padding: @space-xl 0;

            &-title {
                font-size: 54px;
                color: @white;
                font-weight: 700;
                letter-spacing: 2px;
                opacity: 0;
            }

            &-para {
                font-size: @text-xl;
                margin-top: @space-sm;
                opacity: 0;

            }

            .btn {
                margin-top: @space-xl;
                opacity: 0;
            }
        }

    }

    .owl-item {
        &.active {
            .slide__content-title {
                animation: 1250ms 1250ms fadeInUp forwards;
            }

            .slide__content-para {
                animation: 1250ms 1500ms fadeInUp forwards;
            }

            .btn {
                animation: 1250ms 1750ms fadeInUp forwards;
            }
        }
    }
}

/* --------------------------------
---- Offer ---- */
.section-offer-tiles {
    background-color: @white-dimmed;
}

.offer-grid {
    margin-top: @space-xl;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 300px;
    grid-gap: @space-md;
}

.offer-tile {
    background-size: cover;
    background-position: center center;
    position: relative;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(rgba(0, 0, 0, 0.205), rgba(0, 0, 0, 0.205));
    border-radius: 5px;

    &__name {
        position: absolute;
        left: @space-md;
		color: @white;
		text-transform: capitalize;

        span {
            font-size: @text-sm;
            font-weight: 400;
        }
    }

    &__button {
        position: absolute;
        bottom: @space-md;
        right: @space-md;
        display: inline-block;
        padding: @space-sm @space-md;
        color: @color-text;
        border: 2px solid @white;
        border-radius: 100px;
        background-color: @white;
        text-transform: uppercase;
        font-size: @text-md;
        font-weight: 600;
        transition: all 150ms;

        &:hover,
        &:focus,
        &:active {
            color: @white;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}

/* --------------------------------
---- Animals ---- */

.section-animals {
    margin-top: @space-xl;
    background-color: @color-primary;
    height: 550px;
    position: relative;
}

.animals__flexbox {

    .image-wrapper {
        position: absolute;
        top: -@space-xl;
        left: 0;
        width: 65%;
        height: calc(100% + @space-xl);

        img {
            border-radius: 5px;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    .animals__description {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        width: 50%;
        max-width: 625px;
        background-color: @white;
        border-radius: 5px;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
        padding: @space-xl;

        .text-content {
            margin-top: @space-lg;
        }

        .btn {
            margin-top: @space-xl;
            display: inline-block;
            margin-left: auto;
        }
    }
}

.animals__action-wrapper {
    margin-top: @space-xl;
    display: flex;
    justify-content: center;
}

/* --------------------------------
---- Partners ---- */
.section-partners {
    padding: 0;
}

.partners-carousel {}

.owl-carousel {
    .owl-stage {
        display: flex;
        align-items: center;
    }
}

/* --------------------------------
---- News ---- */
.section-news {
    .action-wrapper {
        margin-top: @space-xl;
        display: flex;
        justify-content: center;
    }
}

.articles-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: @space-xl;

    .article-prev {
        display: block;
        width: calc(100% / 3 - @space-md);
        margin-top: @space-xl;
        padding: @space-md;
        border: 1px solid @color-border-light;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.025);
        border-radius: 5px;

        &:nth-child(-n + 3) {
            margin-top: 0;
        }

        &--hidden {
            visibility: hidden !important;
            opacity: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
            height: 0 !important;
        }

        .image-wrapper-link {
            overflow: hidden;
            display: block;
        }

        h3 {
            margin-top: @space-sm;
            font-size: @text-lg;
        }

        p {
            margin-top: @space-sm;
        }

        .read-more {
            color: @color-primary;
            display: block;
            margin-left: auto;
            margin-top: @space-lg;
            text-align: right;
        }
    }
}

.pagination {
    margin-top: @space-xl;

    &__list {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        li {
            margin: 0 0.5rem;

            a {
                border-bottom: 1px solid;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.25rem 0.55rem;
                font-size: 1rem;
                transition: all 200ms;

                &:hover,
                &:active {
                    color: @color-primary;
                    border-bottom: 1px solid @color-secondary;
                }
            }

            &.active {
                a {
                    color: @color-primary;
                    border-bottom: 1px solid @color-secondary;
                }
            }
        }
    }
}


/* --------------------------------
---- Section About ---- */
.section-about {
    position: relative;
    color: @white;
    height: 550px;

    &__map {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 65%;
    }

    #location-map {
        height: 100%;
        min-height: 550px;
        width: 100%;
        display: block;
    }

    .map-marker__content {
        color: @color-text;
        text-align: center;
    }

    .map-marker__title {}

    .map-marker__desc {
        margin-top: @space-xs;
    }
}

.about-content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: calc(60% - @space-lg);
    z-index: 1;
    background-color: @color-primary;
    padding: @space-xl @space-xl @space-xl 200px;
    border-radius: 5px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

    h2 {
        color: @white;
        font-size: @text-xxxl;

        span {
            font-weight: 400;
            font-size: @text-xxl;
        }
    }

    .text-content {
        margin-top: @space-lg;
    }

    .btn {
        margin-top: @space-xl;
    }
}

/* ------------------------------------
---- Aktualności Wpis - strona ---- */

section.article-section-content {
    .article-image-wrapper {
        margin-top: @space-xl;

        img {
            margin: 0 auto;
            display: block;
            width: 70%;
            border-radius: 5px;
        }
    }

    .article-content {
        margin-top: @space-xl;
    }
}


/* --------------------------------
---- O firmie              ---- */

section.about-section-content {
    .o-firmie-content {
        margin-top: @space-xl;

        .text-content {
            margin-top: @space-xl;

            &:first-child {
                margin-top: 0;
            }
        }

        .img-1 {
            width: 70%;
            border-radius: 5px;
            margin: @space-xl auto;
            display: block;

            @media @md {
                width: 100%;
            }
        }
    }

    .section-partners {
        margin-top: @space-xl;
    }

    .gallery-flexbox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: @space-xl;

        &__item {
            width: calc(25% - 20px);
            height: 150px;
            margin-bottom: 20px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

            a {
                display: block;
                height: 100%;
                width: 100%;
                position: relative;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
            }

            &--hidden {
                height: 0 !important;
                padding: 0;
                margin: 0 !important;
                opacity: 0;
                visibility: hidden;

            }
        }
    }
}

/* --------------------------------
---- Kontakt              ---- */

section.contact-section-content {
    .contact-flexbox {
        display: flex;
        justify-content: space-between;

        .contact-service {
            width: 48%;

            h3 {
                margin-bottom: @space-lg;
            }

            address {
                font-size: @text-lg;

                strong {
                    color: @color-secondary;
                    font-size: @text-xl;
                }
            }

            .contact-tel,
            .contact-mail {
                font-size: @text-lg;
            }

            .contact-form {
                .label {
                    margin-bottom: 0;
                    font-weight: 600;
                    font-family: @font-secondary;
                }

                .input {
                    border-color: @color-border-light;
                    border-radius: 3px;
                    background-color: @white-dimmed;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.075);
                }

                .form-group {

                    &:nth-of-type(6),
                    &:nth-of-type(7) {
                        margin-bottom: @space-xs;
                    }


                    .form-check {
                        .form-check-label {
                            font-size: @text-sm;
                        }
                    }
                }
            }
        }
    }
}

section.section-contact-map {
    .location-map-wrapper {
        height: 450px;
        width: 100%;
    }
}


/* --------------------------------
---- Referencje - strona   ---- */

section.referencje-section-content {
    background-color: @white;

    .testimonials-flexbox {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .testimonials-service {
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: @space-xl;
            border: 1px solid @color-border-light;
            border-radius: 5px;
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.025);
            margin-top: @space-xl;
            text-align: center;
            background-color: @white-dimmed;

            &:nth-child(even) {
                margin-top: @space-xxl;
            }

            img {
                border-radius: 50%;
                width: 100px;
                margin: 0 auto;
            }

            h3 {
                margin-top: @space-lg;
                font-size: @text-xl;
            }

            p {
                margin-top: @space-lg;

            }
        }
    }
}


/* --------------------------------
---- Faq - strona          ---- */

section.faq-section-content {
    .faq-flexbox {
        margin-top: @space-xl;

        .faq-service {
            width: 85%;
            margin-top: @space-xl;
            padding: @space-xl;
            border-top: 1px solid @color-border-light;
            border-bottom: 1px solid @color-border-light;
            background-color: @white-dimmed;
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.025);

            &:nth-child(even) {
                text-align: right;
                margin-left: auto;

                .question {
                    margin-left: auto;
                }
            }

            .question {
                color: @color-secondary;
                font-weight: 400;
                font-size: @text-lg;
                width: 70%;
            }

            .answer {
                margin-top: @space-lg;
            }
        }
    }
}


/* --------------------------------
---- Komitet - strona      ---- */

section.komitet-section-content {
    .text-content {
        margin-top: @space-xl;
        width: 80%;
    }

    .komitet-cta {
        width: 690px;
        background-color: @color-secondary;
        color: @white;
        border-radius: 5px;
        margin: @space-xl 0 0;
        padding: @space-xl;
        text-align: center;
        font-size: @text-lg;

        h3 {
            font-size: @text-xxl;
            font-weight: 700;
            color: @white;
            margin-bottom: @space-md;
        }

        .bank {
            margin-top: @space-md;
            font-size: @text-xl;
            font-weight: 700;
        }

        .percent {}
    }
}

section.komitet-section-pictures {
    .animals-gallery {
        margin-top: -@space-lg;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .image-wrapper {
            margin-top: @space-lg;
            width: calc(100% / 5 - @space-md);
            border-radius: 5px;
            overflow: hidden;
            height: 175px;
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            &--hidden {
                height: 0 !important;
                padding: 0 !important;
                margin: 0 !important;
                opacity: 0 !important;
                visibility: hidden;
            }
        }
    }
}

section.komitet-section-content-more {
    .text-content {
        width: 80%;
    }
}

/* --------------------------------
---- Oferta - strona       ---- */
section.oferta-section-content {
	
	.offer-product-flexbox {
		display: flex;
        justify-content: space-between;
        margin-top: @space-xl;
		
    }
	
    .offer-product-image-wrapper {
		width: calc(40% - @space-xl);
		
        img {
			border-radius: 5px;
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.125);
        }
    }
	
    .offer-product-description {
		width: 60%;
		
        .text-content {
			margin-top: @space-lg;
        }
		
        .offer-product-features {
			margin: @space-lg 0 0 0;
            list-style: none;
            padding: 0 0;
			
            li {
				padding-left: @space-lg;
                text-indent: -@space-sm;
				
                &::before {
					content: "• ";
                    position: relative;
                    left: -@space-xs;
                    color: red;
                }
            }
        }
		
        .calculator-link {
			margin-top: @space-xl;
            display: flex;
            display: inline-block;
            padding: @space-md @space-lg;
            line-height: 1;
            background-color: @color-primary;
            color: @white;
            font-size: @text-lg;
            font-weight: 700;
            border-radius: 5px;
			
            img {
				display: inline-block;
                height: 20px;
                width: 20px;
                margin-right: @space-sm;
            }
        }
    }
}


/* ----------------------------------------
---- Polityki regulaminy - strona  ---- */

.section-policy-content {
	.text-content {
		margin-top: @space-xl;
	}
}