@media (max-width: 1279.98px) {
    .animals__flexbox {
        .animals__description {
            right: 1rem;
            left: unset;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .about-content {
        padding: @space-xl;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    nav.section-header {
        .flexbox-logo {
            padding: 0 @space-lg;
            min-height: 85px;
        }

        .header-content {
            height: 100px;
        }

        .header-top {
            ul {
                li {
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
        }

        .header-flexbox {
            .nav-button {
                display: flex;
                margin-right: -10px;
            }

            .flexbox-nav-wrapper {
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                overflow-y: scroll;
                background: #fff;
                z-index: 555;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s;

                .nav-button-close {
                    display: block;
                }

                .flexbox-nav-inner {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    min-height: 100%;
                    padding: 50px;
                    margin-right: 0;

                    .flexbox-nav {
                        height: 100%;
                        width: 100%;
                        flex-direction: column;
                        text-align: center;

                        li {
                            margin: 0;
                            padding: @space-xs 0;

                            &:last-child {
                                a {
                                    padding-right: @space-sm;
                                }
                            }

                            &.has-child {

                                .dropdown-list {
                                    position: relative;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    align-items: center;
                                    top: unset;
                                    transform: translate(0, 0);
                                    box-shadow: none;
                                    border: none;
                                    border-radius: 0;
                                    width: auto;
                                    list-style: none;
                                    opacity: 1;
                                    visibility: visible;
                                    transition: all 300ms;
                                    padding: 0;
                                    max-height: 0;
                                    overflow: hidden;

                                    li {
                                        width: auto;
                                        font-size: @text-sm;
                                        font-weight: 400;
                                    }
                                }

                                &.active {
                                    .dropdown-list {
                                        max-height: 600px;
                                    }
                                }

                                &:hover,
                                &:focus {
                                    .dropdown-list {
                                        opacity: 1;
                                        visibility: visible;
                                        transform: translate(0, 0);
                                    }
                                }

                            }

                            a {
                                padding: @space-sm;
                            }
                        }
                    }
                }

                &.active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .section-footer {
        .footer-flexbox {
            flex-wrap: wrap;
        }

        .footer-item {
            width: calc(50% - @space-sm);

            &:nth-child(1),
            &:nth-child(4) {
                order: -1;
            }

            &:nth-child(2),
            &:nth-child(3) {
                margin-top: @space-xl;
            }
        }
    }

    .offer-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .about-content {
        padding: @space-lg;

        h2 {
            font-size: @text-xxl;
        }

        .btn {
            margin-top: @space-xl;
        }
    }

    .section-animals {
        margin-top: 0;
        padding: 0;
        height: 500px;
        border-top: 1px solid @color-border-light;
        background-color: @white;
    }

    .animals__flexbox {
        display: flex;
        justify-content: space-between;
        height: 500px;

        .image-wrapper {
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            width: 50%;
            height: 100%;
            border-radius: 0;


            img {
                border-radius: 0;
            }
        }

        .animals__description {
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            transform: translate(0, 0);
            width: auto;
            max-width: unset;
            width: 50%;
            box-shadow: none;
            border-radius: 0;
            padding: @space-xl @space-lg;

            .text-content {}

            .btn {
                margin-top: @space-lg;
            }
        }
    }

    section.faq-section-content {
        .faq-flexbox {

            .faq-service {
                width: 100%;
                margin-top: @space-lg;

                .question {
                    width: 90%;
                }
            }
        }
    }

    section.referencje-section-content {
        .testimonials-flexbox {
            .testimonials-service {
                padding: @space-md;
            }
        }
    }

    .articles-flexbox {
        .article-prev {
            width: calc(100% / 3 - @space-sm);

            &:nth-child(-n + 3) {
                margin-top: 0;
            }
        }
    }

    section.komitet-section-content {
        .text-content {
            width: 100%;
        }

        .komitet-cta {
            width: 100%;

        }
    }

    section.komitet-section-pictures {
        .animals-gallery {
            .image-wrapper {
                width: calc(100% / 4 - @space-sm);
                margin-top: @space-md;
            }
        }

    }

    section.komitet-section-content-more {
        .text-content {
            width: 100%;
        }
    }

    section.about-section-content {
        .gallery-flexbox {
            &__item {
                width: calc(100% / 3 - @space-sm);
            }
        }
    }


}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    nav.section-header {
        .flexbox-logo {
            padding: 0 @space-lg;
            min-height: 85px;
        }

        .header-top {
            a {
                display: none;
            }

            ul {
                li {
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
        }
    }

    .owl-carousel-slider {
        .slide {
            &__content {
                left: @space-lg;
                width: 75%;

                &-title {
                    font-size: 40px;
                }

                &-para {
                    font-size: @text-md;
                }

                .btn {
                    padding: @space-sm @space-lg;
                }
            }
        }
    }

    .section-about {
        position: relative;
        color: @white;
        height: auto;
        padding: 0;

        &__map {
            position: relative;
            top: unset;
            right: unset;
            height: 350px;
            width: 100%;
        }

        #location-map {
            min-height: unset;
        }
    }

    .about-content {
        position: relative;
        top: unset;
        left: unset;
        transform: translateY(0);
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    .section-animals {
        height: auto;
    }

    .animals__flexbox {
        flex-wrap: wrap;
        height: auto;

        .image-wrapper {
            width: 100%;
        }

        .animals__description {
            width: 100%;
            padding: @space-xl 15px;
        }
    }

    section.oferta-section-content {
        .offer-product-flexbox {
            flex-wrap: wrap;
        }

        .offer-product-image-wrapper {
            width: 100%;

            img {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
        }

        .offer-product-description {
            width: 100%;
            margin-top: @space-xl;
        }
    }

    section.faq-section-content {
        .faq-flexbox {
            .faq-service {
                padding: @space-lg;
            }
        }
    }

    section.contact-section-content {
        .contact-flexbox {
            flex-wrap: wrap;

            .contact-service {
                width: 100%;

                &:nth-child(2) {
                    margin-top: @space-xl;
                }
            }
        }
    }

    section.section-contact-map {
        .location-map-wrapper {
            height: 450px;
            width: 100%;
        }
    }

    .articles-flexbox {
        .article-prev {
            width: calc(100% / 2 - @space-sm);
            margin-top: @space-lg;

            &:nth-child(-n + 3) {
                margin-top: @space-lg;
            }

            &:nth-child(-n + 2) {
                margin-top: 0;
            }
        }
    }

    section.komitet-section-content {
        .komitet-cta {
            padding: @space-lg;

            h3 {
                font-size: @text-xl;

                span {
                    display: block;
                }
            }

            .bank {
                font-size: @text-lg;
            }
        }
    }

    section.komitet-section-pictures {
        .animals-gallery {
            .image-wrapper {
                width: calc(100% / 3 - @space-sm);
                height: 150px;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    nav.section-header {
        .flexbox-logo {
            padding: 0 15px 0 0;
            min-height: 85px;
        }

        .header-content {
            height: auto;
        }

        .header-top {
            padding: @space-xs 0;

            a {
                display: none;
            }

            ul {
                li {
                    margin: 0;

                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
        }
    }

    section.about-section-content {
        .gallery-flexbox {
            &__item {
                height: 125px;
            }
        }
    }

    section.referencje-section-content {
        .testimonials-flexbox {
            .testimonials-service {
                width: 100%;
                margin-top: @space-lg;

                &:nth-child(even) {
                    margin-top: @space-lg;
                }
            }
        }
    }

    .articles-flexbox {
        .article-prev {
            width: calc(100% / 2 - @space-sm);

        }
    }

    section.komitet-section-content {
        .komitet-cta {
            font-size: @text-md;

            h3 {
                font-size: @text-lg;
            }

            .bank {
                font-size: @text-md;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 500px) {
    .offer-grid {
        grid-template-columns: 1fr;
        grid-auto-rows: 250px;
    }

    .section-footer {
        .footer-item {
            width: 100%;
            margin-top: @space-xl;
            text-align: center;

            &:nth-child(1),
            &:nth-child(4) {
                order: -1;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .articles-flexbox {
        .article-prev {
            width: 100%;
            margin-top: @space-lg;

            &:nth-child(-n + 3) {
                margin-top: @space-lg;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    section.komitet-section-pictures {
        .animals-gallery {
            .image-wrapper {
                width: calc(100% / 2 - @space-xs);
                margin-top: @space-sm;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {
    .owl-carousel-slider {
        .slide {
            &__content {

                &-title {
                    font-size: @text-xxl;
                }

                &-para {}

                .btn {}
            }
        }
    }

    section.oferta-section-content {
        .offer-product-description {
            .calculator-link {
                font-size: @text-md;
            }
        }
    }

    section.about-section-content {
        .gallery-flexbox {
            &__item {
                width: calc(100% / 2 - @space-sm);
            }
        }
	}
	
	.system-alerts {
		.alert {
			.alert-inner {	
				p {
					padding: 0 ;
				}
			}
		}
	}
}

@media (max-width: 335px) {
    nav.section-header {
        .flexbox-logo {
            padding: 0 15px 0 0;

            img {
                height: 50px;
            }
        }

        .header-top {
            ul {
                li {
                    a {
                        img {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}